import * as React from 'react';
import { Ratio, type RatioValue } from '../Ratio';
import { Box } from '../Box';
export interface FrameT {
  children: React.ReactNode;
}

export const FrameBrowser = ({
  children,
  ratio = 'landscape',
  customRatio,
}: React.PropsWithChildren<FrameT & RatioValue>) => {
  return (
    <Box
      borderStyle="solid"
      borderColor="borderWeaker"
      borderWidth="2px"
      bg="bg"
      borderRadius="lg"
      width="100%"
      flex="1 1 auto"
      overflow="hidden"
    >
      <Box width="100%" height="28px" bg="primaryWeakest" />
      {customRatio ? (
        <Ratio customRatio={customRatio}>{children}</Ratio>
      ) : (
        <Ratio ratio={ratio}>{children}</Ratio>
      )}
    </Box>
  );
};
