'use client';
import * as React from 'react';
import { Box, Button } from '@mentimeter/ragnar-ui';
import { HeartIcon } from '@mentimeter/ragnar-visuals';
import { useTranslation } from 'react-i18next';
import { H3 } from '../../ui/typography';
import { useAppState } from '../../appState';
import { NotifyForm } from './NotifyForm';
import { useSubscribe } from './useSubscribe';

const CAMPAIGN_ID = '701TX000006kyYpYAI';

const SuccessState = () => {
  return (
    <>
      <HeartIcon color="negative" size={5} />
      <H3 textAlign="center" mb={0}>
        Thank you for subscribing!
      </H3>
    </>
  );
};

interface DownloadGatedContentT {
  fileUrl: string;
  trackingEvent: string;
  salesforceCampaignId: string;
}
export const DownloadGatedContent = ({
  fileUrl,
  trackingEvent,
  salesforceCampaignId = CAMPAIGN_ID,
}: DownloadGatedContentT) => {
  const { user } = useAppState();
  const { status, handleSubscribe } = useSubscribe(trackingEvent, {
    'Salesforce Campaign ID': salesforceCampaignId,
  });
  const { t } = useTranslation('common');
  if (status === 'success') {
    return (
      <Box p={4} alignItems="center">
        <H3 mb={2}>{t('notify_form.check_inbox')}</H3>
        {fileUrl && (
          <Button
            variant="subtle"
            href={fileUrl}
            target="_blank"
            rel="noopener noreferrer"
            data-testid="download-button"
          >
            {t('notify_form.click_to_download')}
          </Button>
        )}
      </Box>
    );
  }
  return (
    <NotifyForm
      buttonTitle={t('notify_form.send_pdf')}
      formFields={{
        email: user?.email || '',
        jobTitle: '',
        company: '',
        name: user?.name || '',
      }}
      status={status}
      handleSubscribe={handleSubscribe}
      consent
    />
  );
};

export const SubscribeToLegalPackageUpdates = () => {
  const { user } = useAppState();
  const { status, handleSubscribe } = useSubscribe(
    'Subscribed to legal package updates',
  );

  return (
    <Box width="100%" alignItems="center">
      {status === 'success' ? (
        <SuccessState />
      ) : (
        <NotifyForm
          formFields={{
            email: user?.email || '',
          }}
          buttonTitle="Subscribe"
          handleSubscribe={handleSubscribe}
          status={status}
        />
      )}
    </Box>
  );
};
