'use client';
import type { Document } from '@contentful/rich-text-types';
import { gtmTrack } from '@mentimeter/google-tracking';
import { HotkeyHandler, HotkeyProvider } from '@mentimeter/hotkeys';
import { blackBase, whiteBase } from '@mentimeter/ragnar-colors';
import {
  Box,
  ModalGeneric,
  Theme,
  Ratio,
  RATIO_LANDSCAPE,
  Text,
} from '@mentimeter/ragnar-ui';
import dynamic from 'next/dynamic';
import { CrossIcon, PlaySolidIcon } from '@mentimeter/ragnar-visuals';
import { useTranslation } from 'react-i18next';
import * as React from 'react';
import { useRef, useState } from 'react';
import loadYoutubeVideo from 'src/utils/loadYoutubeVideo';
import { trackVisitor } from 'src/trackVisitor';
import { CaptionRenderer } from '../cms/CaptionRenderer';
import { CONTENTFUL_THEMES } from './Theme';
import { Button, Clickable } from './actions';
import { Wide } from './layout';

// To make server component rendering on edge possible
const EmbeddedVimeo = dynamic(() => import('./EmbeddedVimeo'), { ssr: false });

export interface EmbeddedVideoT {
  url: string;
  title?: string | undefined;
}

export type VideoT = EmbeddedVideoT & {
  preview?: string | undefined;
  caption?: Document;
  faded?: boolean;
  ratio?: string;
  PlayButton?: React.ComponentType<{
    onClick: React.MouseEventHandler;
  }>;
  onShow?: () => void;
  onClose?: () => void;
  experiment?: boolean;
};

const defaultVideoProps = {
  frameBorder: '0',
  allowFullScreen: true,
  width: '100%',
  height: '100%',
  sandbox: 'allow-scripts allow-same-origin',
};

interface EmbeddedYoutubeProps {
  url: string;
  onPlay: () => void;
}

const EmbeddedYoutube = ({ url, onPlay }: EmbeddedYoutubeProps) => {
  const videoRef = useRef<HTMLDivElement | null>(null);

  React.useEffect(() => {
    if (videoRef.current) {
      loadYoutubeVideo({
        url,
        onPlay,
        container: videoRef.current,
        options: defaultVideoProps,
      });
    }
  }, [videoRef, url, onPlay]);

  return <div ref={videoRef}></div>;
};

export const EmbeddedVideo = ({ url, title }: EmbeddedVideoT) => {
  const isVimeo = url.includes('player.vimeo.com/video/');
  const isYoutube =
    url.includes('youtube.com/embed/') ||
    url.includes('youtube-nocookie.com/embed/');

  const onPlay = React.useCallback(
    () =>
      title &&
      gtmTrack({
        event: 'watchVideo',
        type: title,
        provider: isVimeo ? 'vimeo' : 'youtube',
      }),
    [title, isVimeo],
  );

  if (!isVimeo && !isYoutube) return null;

  return (
    <Ratio ratio="landscape">
      {isVimeo && <EmbeddedVimeo url={url} onPlay={onPlay} title={title} />}
      {isYoutube && <EmbeddedYoutube url={url} onPlay={onPlay} />}
    </Ratio>
  );
};

export const Video = ({
  PlayButton,
  preview,
  url,
  title,
  caption,
  faded = false,
  ratio = RATIO_LANDSCAPE,
  onShow,
  onClose,
  experiment,
}: VideoT) => {
  const [show, setShow] = useState(false);

  const { t } = useTranslation('common');

  const onPlay = () => {
    if (title === 'Hello Video') {
      trackVisitor({
        event: 'Clicked on homepage video',
      });
    }

    if (onShow) {
      onShow();
    }
    setShow(true);
  };
  const onDismiss = () => {
    if (onClose) {
      onClose();
    }
    setShow(false);
  };

  if (preview) {
    return (
      <>
        <HotkeyProvider
          instance="video-landing-page-modal"
          keyStrokes={['escape']}
        >
          <ModalGeneric show={show} onDismiss={onDismiss} center>
            <HotkeyHandler
              onKeyUp={(e) => {
                e.preventDefault();
                onDismiss();
              }}
              keyCode="escape"
            />
            <Box
              width="95vw"
              height="90vh"
              alignItems="center"
              justifyContent="center"
              overflow="hidden"
            >
              <Box position="absolute" top="20px" right="20px">
                <Clickable
                  bg="neutralWeakest"
                  borderRadius={3}
                  onClick={(e) => {
                    e.preventDefault();
                    onDismiss();
                  }}
                  width="60px"
                  height="60px"
                  alignItems="center"
                  justifyContent="center"
                >
                  <CrossIcon size={3} color="text" />
                  <Text fontSize="75" color="text">
                    esc
                  </Text>
                </Clickable>
              </Box>
              <Wide width="100%">
                <Ratio ratio="landscape">
                  <EmbeddedVideo url={url} title={title} />
                </Ratio>
              </Wide>
            </Box>
          </ModalGeneric>
        </HotkeyProvider>
        <Box
          borderRadius={1}
          minWidth="100%"
          bg="text"
          alignItems="center"
          justifyContent="center"
          overflow="hidden"
        >
          <Theme theme={CONTENTFUL_THEMES.plain}>
            <Ratio
              customRatio={ratio}
              backgroundImage={`url(${preview}?w=800&q=100)`}
              backgroundSize="cover"
              width="100%"
              overflow="hidden"
              top="0"
              left="0"
            />
            <Clickable
              aria-label={t('common:page_links.watch_video')}
              position="absolute"
              top="0"
              left="0"
              onClick={onPlay}
              width="100%"
              height="100%"
              bg="bg"
              extend={({ theme }) => ({
                opacity: faded ? theme.opacity.faded : 0,
              })}
            />
            <Box
              position="absolute"
              top="50%"
              left="50%"
              extend={() => ({
                transform: 'translate(-50%, -50%)',
              })}
            >
              {PlayButton ? (
                <PlayButton onClick={onPlay} />
              ) : (
                <Button
                  variant="primary"
                  onClick={onPlay}
                  iconTrailing={!experiment ? PlaySolidIcon : undefined}
                  iconLeading={experiment ? PlaySolidIcon : undefined}
                  extend={
                    experiment
                      ? ({ theme }) => ({
                          color: theme.colors.onPrimary,
                          backgroundColor: blackBase,
                          borderColor: whiteBase,
                          borderRadius: `${theme.radii[3]}px`,
                          ':hover': {
                            color: theme.colors.primary,
                            backgroundColor: whiteBase,
                            borderColor: theme.colors.primary,
                          },
                          ':active': {
                            color: theme.colors.primary,
                            backgroundColor: whiteBase,
                            borderColor: theme.colors.primary,
                          },
                        })
                      : undefined
                  }
                >
                  {experiment ? 'PLAY' : t('common:page_links.watch_video')}
                </Button>
              )}
            </Box>
          </Theme>
          {caption && <CaptionRenderer document={caption} />}
        </Box>
      </>
    );
  }

  return (
    <>
      <HotkeyProvider
        instance="video-landing-page-modal"
        keyStrokes={['escape']}
      >
        <ModalGeneric show={show} onDismiss={onDismiss} center>
          <HotkeyHandler
            onKeyUp={(e) => {
              e.preventDefault();
              onDismiss();
            }}
            keyCode="escape"
          />
          <Box
            width="100vw"
            height="100vh"
            alignItems="center"
            justifyContent="center"
            overflow="hidden"
          >
            <Box position="absolute" top="20px" right="20px">
              <Clickable
                bg="neutralWeakest"
                borderRadius={3}
                onClick={onDismiss}
                width="60px"
                height="60px"
                alignItems="center"
                justifyContent="center"
              >
                <CrossIcon size={3} color="text" />
                <Text fontSize={0} color="text">
                  esc
                </Text>
              </Clickable>
            </Box>
            <Wide width="100%">
              <Ratio ratio="landscape">
                <EmbeddedVideo url={url} title={title} />
              </Ratio>
            </Wide>
          </Box>
        </ModalGeneric>
      </HotkeyProvider>
      {PlayButton ? (
        <PlayButton onClick={onPlay} />
      ) : (
        <EmbeddedVideo url={url} title={title} />
      )}
      {caption && <CaptionRenderer document={caption} />}
    </>
  );
};
