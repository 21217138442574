import type { Document } from '@contentful/rich-text-types';
import { designSystemConfig } from '@mentimeter/ragnar-dsc';
import { Box, Ratio, Text, TooltipGeneric } from '@mentimeter/ragnar-ui';
import { contentfulImageLoader } from 'src/cms/utils/utils';
import type { NextImageProps } from 'src/components/image/NextImage';
import NextImage from 'src/components/image/NextImage';
import type { IPersonFields } from 'types/generated/contentful';
import { BodyRenderer } from '../../cms/BodyRenderer';
import { DescriptionRenderer } from '../../cms/DescriptionRenderer';
import { Limit, Section, Wide } from '../layout';
import type { ThemeT } from '../Theme';
import { H2, P } from '../typography';

export interface TeamMemberT extends Omit<IPersonFields, 'image'> {
  id: string;
  image: NextImageProps;
}

export interface TeamT extends ThemeT {
  body?: Document | undefined;
  heading?: string;
  persons: Array<TeamMemberT>;
  text?: string;
  variant?: 'default' | 'detailed' | undefined;
}

const colors = [...designSystemConfig.visualizationColors.fillColors];

export function Team({ body, heading, persons, text, theme, variant }: TeamT) {
  return (
    <Section theme={theme}>
      <Wide alignItems="stretch">
        {(body || heading) && (
          <Limit mb={[2, 4]}>
            {body ? (
              <BodyRenderer document={body} />
            ) : (
              <>
                {heading && <H2>{heading}</H2>}
                {text && <P>{text}</P>}
              </>
            )}
          </Limit>
        )}
        {variant === 'detailed' ? (
          <Box mx={[0, -3, null, -4]}>
            <Box flexDirection="row" flexWrap="wrap" width="100%">
              {persons.map(
                ({ id, image, name, lastName, detailedDescription }) => {
                  return (
                    <Box
                      width={[1, 1 / 2, 1 / 3]}
                      key={id}
                      px={[0, 3, null, 4]}
                      mb={[3, 4, null, 5]}
                      alignItems="flex-start"
                    >
                      <Ratio>
                        <NextImage
                          {...image}
                          style={{ objectFit: 'cover', height: '100%' }}
                          width={400}
                        />
                      </Ratio>
                      <Text
                        fontSize={[3, null, null, 4]}
                        textAlign="center"
                        fontWeight="semiBold"
                        color="text"
                        lineHeight="heading"
                        mt={3}
                      >
                        {name} {lastName}
                      </Text>
                      {detailedDescription && (
                        <Box mt={3}>
                          <DescriptionRenderer document={detailedDescription} />
                        </Box>
                      )}
                    </Box>
                  );
                },
              )}
            </Box>
          </Box>
        ) : (
          <Box mx={[-2, -3, null, -4]}>
            <Box flexDirection="row" flexWrap="wrap" width="100%">
              {persons.map(({ description, id, image, name, role }) => {
                const colorIndex = Math.floor(Math.random() * colors.length);
                return (
                  <Box
                    width={[1 / 3, 1 / 5]}
                    key={id}
                    px={[2, 3, null, 4]}
                    mb={[3, 4, null, 5]}
                    alignItems="center"
                  >
                    <Box
                      borderRadius={3}
                      overflow="hidden"
                      id={id}
                      bg="neutralWeakest"
                      width="100%"
                      mb={3}
                      extend={() => ({
                        transition: 'all 0.25s ease',
                        transformOrigin: 'center bottom',
                        ':hover': {
                          transform: `scale(1.05)`,
                          background: `${colors[colorIndex]}`,
                        },
                        ':hover > img': {
                          transform: `scale(1.15) translateY(15px)`,
                        },
                      })}
                    >
                      <NextImage
                        {...image}
                        width={200}
                        height={200}
                        quality={100}
                        unoptimized
                        src={contentfulImageLoader({
                          width: 200,
                          format: 'webp',
                          quality: 100,
                          src: image.src,
                        })}
                        style={{
                          transition: 'all .25s ease',
                          transformOrigin: 'center bottom',
                        }}
                      />
                    </Box>
                    <Text
                      fontSize={[2, null, null, 3]}
                      textAlign="center"
                      fontWeight="semiBold"
                      color="text"
                      lineHeight="heading"
                    >
                      {name}
                    </Text>
                    <Text
                      mt={1}
                      fontSize={[1, null, null, 2]}
                      textAlign="center"
                      color="textWeaker"
                      lineHeight="heading"
                    >
                      {role}
                    </Text>
                    {description && (
                      <TooltipGeneric
                        referenceId={id}
                        bg="text" //just the arrow
                        placement="bottom"
                      >
                        <Box
                          width="190px"
                          maxWidth="220px"
                          p={2}
                          bg="text"
                          borderRadius={1}
                          alignItems="center"
                        >
                          <Text
                            fontSize={1}
                            textAlign="center"
                            fontWeight="semiBold"
                            color="bg"
                            lineHeight="body"
                          >
                            {description}
                          </Text>
                        </Box>
                      </TooltipGeneric>
                    )}
                  </Box>
                );
              })}
            </Box>
          </Box>
        )}
      </Wide>
    </Section>
  );
}
